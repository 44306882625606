import http from '../utils/request'

const serveUrl: any = {
    entityList: '/conversion/get/entity/list',
}

// 商品列表
export const entityList = () => {
    return http.request({
        url: serveUrl.entityList,
        method: 'GET'
    })
}
