export interface converInit<T, K> {
    before_integral: number
    content: K
    createAt: K
    falg: number
    id: number
    image: K
    is_kind: T
    is_vip: T
    now_integral: number
    sort: any
    stock: number
    title: K
    updateAt: K
    uuid: K
}

export class ConversionList {
    list: converInit<number, string>[] = []
}